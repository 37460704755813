<template>
  <div>
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <v-row style="margin-top: 25px">
          <v-col cols="8" sm="8" md="8" class="pt-0">
            <h2>{{ title.label }} : {{ title.descricao }}</h2>
            <h6>{{ title.public_id }}</h6>
          </v-col>
        </v-row>
      </v-card-title>
      <v-spacer></v-spacer>
      <v-card-text>
        <table class="border">
          <tr>
            <th v-if="title.cotas_liberadas">COTAS LIBERADAS:</th>
            <th v-if="title.cotas_agendadas">COTAS AGENDADAS:</th>
            <th v-if="title.cotas_atendidas">COTAS ATENDIDAS:</th>
            <th v-if="title.saldo">COTAS SALDO:</th>
            <th v-if="title.performace">PERFORMACE (%):</th>
            <th v-if="title.cotas_liquida">COTA LIQUIDA:</th>
            <th v-if="title.cotas_liquida_performace">
              COTA LIQUIDA PERFORMACE (%):
            </th>
            <th v-if="title.cotas_liquida">COTAS ATENDIDAS QUEBRA:</th>
            <th v-if="title.cotas_liquida">COTAS ATENDIDAS QUEBRA (%):</th>
          </tr>
          <tr>
            <td v-if="title.cotas_liberadas">{{ title.cotas_liberadas }}</td>
            <td v-if="title.cotas_agendadas">{{ title.cotas_agendadas }}</td>
            <td v-if="title.cotas_atendidas">{{ title.cotas_atendidas }}</td>
            <td v-if="title.saldo">{{ title.saldo }}</td>
            <td v-if="title.performace">{{ title.performace.toFixed(2) }} %</td>
            <td v-if="title.cotas_liquida">
              {{ title.cotas_liquida }}
            </td>
            <td v-if="title.cotas_liquida_performace">
              {{ title.cotas_liquida_performace.toFixed(2) }} %
            </td>
            <td v-if="title.cotas_liquida">
              {{ title.cotas_atendidas_quebra }}
            </td>
            <td v-if="title.cotas_liquida">
              {{ title.cotas_atendidas_quebra_perc.toFixed(2) }} %
            </td>
          </tr>
        </table>
      </v-card-text>

      <div style="margin: 20px">
        <v-btn color="info" class="w-100 mt-5" @click="getDownload">
          <v-icon class="mx-2" left dark> mdi-download </v-icon>
          Baixar Planilha</v-btn
        >
      </div>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="detalhes"
          sort-by="-agendado_em"
          class="border"
        >
          <template v-slot:[`item.agendado_em`]="{ item }">
            {{ item.agendado_em | toLocaleDateTimeString }}
          </template>
          <template v-slot:no-data>
            <span>Sem agendamentos</span>
          </template>
        </v-data-table>
        <v-spacer></v-spacer>
        <v-row style="margin-top: 25px">
          <v-col cols="6" sm="6" md="6" class="pt-0">
            <v-data-table
              :headers="headersTotalTransportadora"
              :items="totalPorTransportadora"
              sort-by="id"
              class="border"
            >
              <template v-slot:[`item.peso`]="{ item }">
                {{ converteKg(item.peso) }}
              </template>
              <template v-slot:[`item.performace`]="{ item }">
                {{ item.performace.toFixed(2) }} %
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('close')">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { toLocaleDateTimeString } from '@/mixins/convertion'
import axios from 'axios'

export default {
  name: 'DetalhesProgramacao',
  props: {
    detalhes: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    headersTotalTransportadora: {
      type: Array,
      default: () => [],
    },
    totalPorTransportadora: {
      type: Array,
      default: () => [],
    },
    title: {
      type: Object,
      default: () => {},
    },
    download: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  filters: {
    toLocaleDateTimeString,
  },
  methods: {
    converteKg(item) {
      return (parseFloat(item) * 1000).toFixed(0)
    },
    async getDownload() {
      let data = []
      try {
        const uri = axios.getUri({
          url:
            'api/v1/patio/programacoes' +
            this.download.type +
            '/' +
            this.download.public_id +
            '/download/',
        })
        const base = axios.defaults.baseURL
        // adding slash if necessary
        const slash = base.slice(-1) == '/' ? '' : '/'
        const url = base + slash + uri
        window.open(url)
      } finally {
        this.$emit('loaded')
      }
      return data
    },
  },
}
</script>
<style>
table {
  border-collapse: collapse;
  width: 60%;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 20px;
}
</style>
